import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import LivestreamImg from '../../static/img/Live.png'
import FBLiveImg from '../../static/img/Facebook Live Icon.png'

export const LivestreamPageTemplate = ({ title, backgroundimage, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  // // // Simple GET request using fetch
  // fetch('https://api.npms.io/v2/search?q=react')
  //   .then(response => response.json())
  //   .then(data => {
  //    console.log(data.total)
  //   });

  return (
    <div>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
            })`,
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '150px',
            lineHeight: '1',
            justifyContent: 'space-around',
            alignItems: 'left',
            flexDirection: 'column',
          }}
        >
          <img src={LivestreamImg} alt="Title" style={{ height: '300px' }} />
        </div>
      </div>

      {/* Google Font */}
      <link
        href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
        rel="stylesheet"
      ></link>
      {/* Livestream Info text */}
      <div style={{
        fontSize: '1.2em',
        lineHeight: '2em',
        letterSpacing: '0.06em',
        fontFamily: 'Poppins',
        color: '#994680',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '25%',
        marginRight: '25%',
        marginTop: '40px',
        marginBottom: '40px',
        textAlign: 'center',
        }}
      >
        <p><b>Click the button</b> below to jump to our <b>Facebook page</b>, where you can watch <b>live recordings</b> of our services on Sundays</p>
      </div>

      <div style={{
        textAlign: 'center'
      }}>
        {/* live_videos link was removed because Facebook is very inconsistent; that link will only work on browsers but not through theapp */}
        <a title="Facebook" href="https://www.facebook.com/RedBankMarionville/videos/"> 
          <img
            className="link-button"
            src={FBLiveImg}
            alt="Facebook"
            style={{
              height: '150px',
              justifyContent: 'center',
              marginBottom: '60px'
            }}
          />
        </a>
      </div>

      {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: '50px'}}>
        <iframe width="560" height="315" src="https://www.facebook.com/RedBankMarionville/live_videos/" frameborder="0" allowfullscreen></iframe>
      </div> */}
    </div>
  )
}

LivestreamPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const LivestreamPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <LivestreamPageTemplate
        backgroundimage={frontmatter.backgroundimage}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

LivestreamPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default LivestreamPage

export const livestreamPageQuery = graphql`
  query livestreamPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`